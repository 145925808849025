/* eslint-disable */
import Vue from 'vue'
import Header from '../../../Header.vue'
import Footer from '../../../Footer.vue'
import UserLeft from '../../PersonUserLeft.vue'
import CheckInService from '../../../../api/CheckInService.js'
import MD5 from 'js-md5'

export default{
	name:'EditPerPassword',
	data(){
		return{
			oldPassword:'',
			newPassword:'',
			confirmPassword:''
		}
	},
	methods:{
		confirmEdit(){ //确定修改
			var that = this
			if(that.oldPassword.trim() == '' || that.oldPassword.trim().length < 6 || that.oldPassword.trim().length > 20 ){
				$('#oldPassword input').addClass('error')
				$('#oldPassword .zxtgerror').text('请输入6-20字符的原密码')
			}else if(that.newPassword.trim() == ''){
				$('#oldPassword input').removeClass('error')
				$('#oldPassword .zxtgerror').text('')
				$('#newPassword input').addClass('error')
				$('#newPassword .zxtgerror').text('必填')
			}else if(that.newPassword.trim() .length < 6 || that.newPassword.trim() .length > 20){
				$('#oldPassword input').removeClass('error')
				$('#oldPassword .zxtgerror').text('')
				$('#newPassword input').addClass('error')
				$('#newPassword .zxtgerror').text('请输入6-20字符的原密码')
			}else if(that.confirmPassword.trim() == ''){
				$('#confirmPassword input').addClass('error')
				$('#confirmPassword .zxtgerror').text('必填')
				$('#oldPassword input').removeClass('error')
				$('#oldPassword .zxtgerror').text('')
				$('#newPassword input').removeClass('error')
				$('#newPassword .zxtgerror').text('')
			}else if(that.confirmPassword.trim() .length < 6 || that.confirmPassword.trim() .length > 20){
				$('#confirmPassword input').addClass('error')
				$('#confirmPassword .zxtgerror').text('请输入6-20字符的确认密码')
				$('#oldPassword input').removeClass('error')
				$('#oldPassword .zxtgerror').text('')
				$('#newPassword input').removeClass('error')
				$('#newPassword .zxtgerror').text('')
			}else if(that.confirmPassword.trim() != that.newPassword.trim()){
				$('#confirmPassword input').addClass('error')
				$('#confirmPassword .zxtgerror').text('两次输入的密码不一致')
				$('#oldPassword input').removeClass('error')
				$('#oldPassword .zxtgerror').text('')
				$('#newPassword input').removeClass('error')
				$('#newPassword .zxtgerror').text('')
			}else{
				CheckInService.EditUserPassword(that.$UserID,MD5(that.oldPassword.trim()),MD5(that.newPassword.trim())).then(res=>{
					if(res.Tag == 1){
						that.$alert('修改密码成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.localStorage.removeItem('DHuserID')
									window.localStorage.removeItem('DHuserType')
									window.localStorage.removeItem('DHServiceType')
									Vue.prototype.$UserID = ''
									Vue.prototype.$userType = ''
									Vue.prototype.$ServiceType = ''
									that.$router.push({
										path: '/Login'
									});
								}
							}
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}
			
		}
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
	}
}